import React, { Fragment } from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

import Separator from 'components/separator';
import PublicationDate from 'components/publicationDate';
import ArticleProvider, { ArticleContext } from 'providers/articles';
import type { Article } from 'providers/articles';

const ArticleStyled = styled.article`
  margin: ${({ theme: { rhythm } }) => rhythm(2)} auto;
`;

const LinkStyled = styled(Link)`
  ${({ theme: { colorScheme } }) => `
    color: inherit;
    text-decoration: none;
    background-image: ${[
      'linear-gradient(to top, rgba(0, 0, 0, 0)',
      'rgba(0, 0, 0, 0) 2px',
      `${colorScheme.headers.color} 3px`,
      'rgba(0, 0, 0, 0) 2px)',
    ].join(', ')};
  `}
`;

interface Props {
  filter?: (article: Article) => boolean;
  lang?: 'en-CA' | 'en-FR';
  limit?: number;
}

function ArticlesList({ filter = () => true, lang = 'en-CA', limit }: Props) {
  return (
    <ArticleProvider>
      <ArticleContext.Consumer>
        {(articles) =>
          articles
            .filter(filter)
            .slice(0, limit)
            .map((article, articleKey) => (
              <Fragment key={article.path}>
                {articleKey > 0 && <Separator />}
                <ArticleStyled>
                  <h3>
                    <LinkStyled to={`${article.path}`}>
                      {article.title}
                    </LinkStyled>
                  </h3>
                  <p>{article.description}</p>
                  <PublicationDate date={article.publishedAt} locale={lang} />
                </ArticleStyled>
              </Fragment>
            ))
        }
      </ArticleContext.Consumer>
    </ArticleProvider>
  );
}

export default ArticlesList;
