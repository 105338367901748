import React from 'react';
import { Helmet } from 'react-helmet';

import Section from 'components/section';
import ArticlesList from 'components/articlesList';
import Separator from 'components/separator';
import AboutMe from 'components/aboutMe';
import styled from 'styled-components';

const Title = styled.h1`
  margin-top: 0;
  text-align: center;
`;

const Subtitle = styled.p`
  font-style: italic;
  margin: auto;
  text-align: center;
  @media (min-width: 480px) {
    max-width: 400px;
  }
`;

function Page() {
  return (
    <>
      <Helmet>
        <title>Some thoughts about IT and software development</title>
        <meta name="robots" content="noindex" />
        <meta
          name="description"
          content="Explanations, explorations and opinions about technologies and software development"
        />
      </Helmet>
      <Section>
        <Title style={{ textAlign: 'center' }}>Articles</Title>
        <Subtitle>
          Explorations and personal opinions about technologies and
          software&nbsp;development.
        </Subtitle>
        <Separator />
        <ArticlesList />
        <Separator />
        <AboutMe>
          <h3 style={{ marginTop: '1rem' }}>Hi, I&apos;m Jonathan Experton.</h3>
          <p>
            <em>
              I help companies start, plan, execute and deliver{' '}
              <strong>software development projects</strong> on time, on scope
              and on budget.
            </em>
          </p>
        </AboutMe>
      </Section>
    </>
  );
}

export default Page;
