import React, { createContext, ReactNode, useContext, useMemo } from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import { PageContext, useNavigation } from 'providers/navigation';

interface Page {
  id: string;
  pageContext: PageContext;
  path: string;
}

type Article = Required<Page['pageContext']['frontmatter']> & { path: string };

const ArticleContext = createContext<Array<Article>>([]);

interface QueryResult {
  allSitePage: { nodes: Array<Page> };
}

interface Props {
  children: ReactNode;
}

function ArticleProvider({ children }: Props) {
  const { lang } = useNavigation();
  const results = useStaticQuery<QueryResult>(graphql`
    query Pages {
      allSitePage {
        nodes {
          pageContext
          path
        }
      }
    }
  `);
  const articles = useMemo(
    () =>
      results.allSitePage.nodes
        .filter(
          (page) =>
            // page.pageContext.langKey === lang &&
            typeof page.pageContext.frontmatter !== 'undefined' &&
            page.pageContext.frontmatter.published === true
        )
        .map(
          ({ pageContext: { frontmatter }, path }): Article =>
            ({
              ...frontmatter,
              path,
            } as Article)
        )
        .sort(
          (a, b) =>
            new Date(b.publishedAt!).getTime() -
            new Date(a.publishedAt!).getTime()
        ) || [],
    []
  );
  return (
    <ArticleContext.Provider value={articles}>
      {children}
    </ArticleContext.Provider>
  );
}

const useArticles = () => useContext(ArticleContext);

export type { Article };
export { ArticleContext, useArticles };
export default ArticleProvider;
